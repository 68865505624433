import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';
import ProfileModal from '../Components/ProfileModal';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';
function GroupMembers() {
    const { category } = useParams();
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [viewProfile, setViewProfile] = useState();
    const { groupMembersData: [groupMembersData, setGroupMembersData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        if (groupMembersData === null) {
            setIsLoaderActive(true);
            getData('groupMembers').then(res => {
                setGroupMembersData(res);
                setDataToDisplay(res);
                setIsLoaderActive(false);
                let cat = category;
                if (isUndefinedOrNull(category) || !res.map(x => x.category).includes(category) ) {
                    window.history.pushState({}, '', '/our-research-group/Faculties');
                    cat = 'Faculties';
                }
                document.getElementById(cat)?.scrollIntoView({ behavior: 'smooth' });
            });
        }
        else {
            setDataToDisplay(groupMembersData);
            let cat = !isUndefinedOrNull(category) || groupMembersData.map(x => x.category).includes(category) ? category : 'Faculties';
            document.getElementById(cat)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [dataToDisplay, category])

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/groupMembersBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>GROUP MEMBERS</Header>
                    </div>
                    <div className='pageSegment'>
                        <div className='groupMembersImageContainer'>
                            {sortArrayByElement(dataToDisplay, 'order').map((resGrp) => {
                                return (
                                    <>
                                        <div>
                                            <Header as='h1' className='researchGrpCat' id={resGrp.category.replaceAll('\'', '').replaceAll('/', '-')}>{resGrp.category}</Header>
                                            {resGrp.category !== 'PhD Alumni' &&
                                                <div className='membergGroup'>
                                                    {sortArrayByElement(resGrp.list, 'order').map((member) => {
                                                        return (
                                                            <>
                                                                <div className='imageDetails' key={member.name}>
                                                                    <Image src={member.image} alt='' className='galleryImage' />
                                                                    <div className='groupMembersImageDetails'>
                                                                        <div className='memberDetails'>
                                                                            <div className='memberRole'>{member.role}</div>
                                                                            <Header as='h3' className='memberName'>{member.name}</Header>
                                                                            <div className='memberField' dangerouslySetInnerHTML={{ __html: member.researchTitle }}>
                                                                            </div>
                                                                        </div>
                                                                        {member.profileRequired &&
                                                                            <div onClick={() => setViewProfile(member)} className='viewMore'><u><i>VIEW MORE</i></u></div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            }
                                            {resGrp.category === 'PhD Alumni' &&
                                                <>
                                                    {sortArrayByElement(resGrp.list, 'order').map((member) => {
                                                        return (
                                                            <>
                                                                <div className='PhD alumni' key={member.name}>{member.name}</div>
                                                            </>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    {!isUndefinedOrNull(viewProfile) &&
                        <ProfileModal profileData={viewProfile} onCloseModal={() => setViewProfile()} />
                    }
                </div>
            }
        </>
    );
}

export default GroupMembers;