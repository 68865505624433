export const sortArrayByElement = (array, element) => {
    if (element === 'date') {
        return array.sort((a, b) => {
            let aDate = new Date(a.date);
            let bDate = new Date(b.date);
            return aDate < bDate ? -1 : (aDate > bDate ? 1 : 0);
        });
    }
    return array.sort((a, b) => { return a[element] < b[element] ? -1 : (a[element] > b[element] ? 1 : 0) });
}

export const isUndefinedOrNull = (obj) => {
    return obj === undefined || obj === null || (obj.constructor.toString().includes('Object') && Object.keys(obj).length === 0) || (obj.constructor.toString().includes('Array') && obj.length === 0) || (obj.constructor.toString().includes('String') && obj === '');
}