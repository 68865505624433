import { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Header, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';
import GalleryModal from './../Components/GalleryModal';

function Gallery() {
    const { category } = useParams();
    const [selectedFilter, setSelectedFilter] = useState({});
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [listToDisplay, setListToDisplay] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const { galleryData: [galleryData, setGalleryData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        if (galleryData === null) {
            setIsLoaderActive(true);
            getData('gallery').then(res => {
                setGalleryData(res);
                setDataToDisplay(sortArrayByElement(res, 'order'));
                onLoad(sortArrayByElement(res, 'order'));
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(sortArrayByElement(galleryData, 'order'));
            onLoad(sortArrayByElement(galleryData, 'order'));
        }
    }, [dataToDisplay, category]);

    const onLoad = (data) => {
        let cat = category;
        if (isUndefinedOrNull(category) || !data.map(x => x.category).includes(category)) {
            window.history.pushState({}, '', '/gallery/Awards');
            cat = 'Awards';
        }
        let displayData = data.find(x => x.category === cat);
        setSelectedFilter(displayData);
        setListToDisplay(sortArrayByElement(displayData.list, 'order'));
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }

    const onFilterClick = (selFilterOpt) => {
        setSelectedFilter(selFilterOpt);
        setListToDisplay(sortArrayByElement(selFilterOpt.list, 'order'));
    }

    return (
        <>
            {!isUndefinedOrNull(listToDisplay) && !isUndefinedOrNull(dataToDisplay) &&
                <div className='page' >
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/galleryBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>GALLERY</Header>
                    </div>
                    <div className='galleryContainer pageSegment'>
                        <div className='filterContainer'>
                            {dataToDisplay.map((filterOpts) => {
                                return (
                                    <>
                                        <NavLink to={'/gallery/' + filterOpts.category} className={selectedFilter.category === filterOpts.category ? 'active filterOptions' : 'filterOptions'} onClick={() => onFilterClick(filterOpts)} key={filterOpts.category}>{filterOpts.category}</NavLink>
                                    </>
                                )
                            })}
                        </div>
                        <div className='imageContainer'>
                            {listToDisplay.map((img) => {
                                return (
                                    <>
                                        <div className='imageDetails' key={img.image}>
                                            <Image src={img.image} alt='' className='galleryImage' onClick={() => setSelectedImage(img)} />
                                            <div className='imageTitle'>{img.title.length > 150 ? img.title.substring(0, 150) + '...' : img.title}</div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                    {!isUndefinedOrNull(selectedImage) &&
                        <GalleryModal galleryData={listToDisplay} selectedImage={selectedImage} onCloseModal={() => setSelectedImage()} />
                    }
                </div>
            }
        </>
    );
}

export default Gallery;