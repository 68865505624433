import { useState } from 'react';
import { Icon, Image, Modal, ModalContent } from 'semantic-ui-react';
import { isUndefinedOrNull } from '../Service/Helpers';
function GalleryModal({ galleryData, selectedImage, onCloseModal }) {
    const [open, setOpen] = useState(true);
    const [selectedImageIndex, setSelectedImageIndex] = useState(galleryData.findIndex(object => {
        return object.id === selectedImage.id;
    }));
    const onClose = () => {
        setOpen(false);
        onCloseModal();
    }
    return (
        <>
            <Modal
                onClose={() => onClose()}
                onOpen={() => setOpen(true)}
                open={open}
                dimmer='blurring'
                size='large'
                closeOnEscape={true}
                closeOnDimmerClick={true}
                basic
                closeIcon
                className='galleryModalContainer'
            >
                <ModalContent image className='galleryModal'>
                    <div className='imageModal'>
                        {selectedImageIndex !== 0 &&
                            <Icon className='leftRightArrow' name='chevron left' size='big' onClick={() => setSelectedImageIndex(prev => prev - 1)} />
                        }
                        <div className='imageModalContainer'>
                            <Image size='medium' src={galleryData[selectedImageIndex].image} />
                            {!isUndefinedOrNull(galleryData[selectedImageIndex].title) &&
                                <>
                                    <p dangerouslySetInnerHTML={{ __html: galleryData[selectedImageIndex].title }}></p>
                                </>
                            }
                        </div>
                        {(selectedImageIndex !== galleryData.length - 1) &&
                            <Icon className='leftRightArrow' name='chevron right' size='big' onClick={() => setSelectedImageIndex(prev => prev + 1)} />
                        }

                    </div>
                </ModalContent>
            </Modal>
        </>
    );
}

export default GalleryModal;