import { useContext, useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';
function Facilities() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const { facilitiesData: [facilitiesData, setFacilitiesData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (facilitiesData === null) {
            setIsLoaderActive(true);
            getData('facilities').then(res => {
                setFacilitiesData(res);
                setDataToDisplay(res);
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(facilitiesData)
        }
    }, []);
    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/facilitiesBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>FACILITIES</Header>
                    </div>
                    <div className='facilityEmail'><Header as='h4' className='facilityEmailLabel'>Contact Email: &nbsp;&nbsp;</Header>{dataToDisplay.contactEmail}</div>
                    <div className='facilitiesContainer pageSegment'>
                        {sortArrayByElement(dataToDisplay.list, 'order').map((items) => {
                            return (
                                <>
                                    <div className='facilities' key={items.name}>
                                        <Header as='h2' className='facilitiesHeader'>{items.name}</Header>
                                        <div className='facilitiesDetails'>
                                            <Image src={items.image} alt='' className='facilitiesImg' />
                                            <div className='facilitiesContent' dangerouslySetInnerHTML={{ __html: items.description }}>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            }
        </>
    );
}

export default Facilities;