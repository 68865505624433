import { useContext, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Header, Image, Menu, MenuItem, Segment } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';

function Publications() {
    const { category } = useParams();
    const [activeItem, setActiveItem] = useState();
    const [menuList, setMenuList] = useState([]);
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [filterOptions, setFilterOptions] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState();
    const [listToDisplay, setListToDisplay] = useState([]);
    const { publicationsData: [publicationsData, setPublicationsData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        if (publicationsData === null) {
            setIsLoaderActive(true);
            getData('publications').then(res => {
                setPublicationsData(res);
                setDataToDisplay(sortArrayByElement(res, 'order'));
                onLoad(sortArrayByElement(res, 'order'));
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(sortArrayByElement(publicationsData, 'order'));
            onLoad(sortArrayByElement(publicationsData, 'order'));
        }
    }, [dataToDisplay, category]);

    const onLoad = (data) => {
        let menus = data.filter(x => x.name !== 'Filtering Options').map(x => x.name);
        let cat = category;
        if (isUndefinedOrNull(category) || !menus.includes(category)) {
            window.history.pushState({}, '', '/publications/Publications');
            cat = 'Publications';
        }
        setMenuList(menus);
        let filterList = data.find(x => x.name === 'Filtering Options').list;
        setSelectedFilter(filterList[0]);
        setFilterOptions(filterList);
        if (cat === 'Publications' || cat === 'Conference Presentations') {
            setListToDisplay(sortArrayByElement(data.find(x => x.name === cat).list.filter(x => filterList[0].values.includes(x.year)), 'slNo').reverse());
        }
        else {
            setListToDisplay(sortArrayByElement(data.find(x => x.name === cat).list, 'order'));
        }
        setActiveItem(data.find(x => x.name === cat));
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
    }

    const onFilterClick = (filterItem) => {
        setSelectedFilter(filterItem);
        setListToDisplay(sortArrayByElement(dataToDisplay.find(x => x.name === activeItem.name).list.filter(x => filterItem.values.includes(x.year)), 'slNo').reverse());
    }

    const onMenuSelection = (menu) => {
        let menuDetails = dataToDisplay.find(x => x.name === menu);
        setActiveItem(menuDetails);
        if (menuDetails.name === 'Publications') {
            setListToDisplay(sortArrayByElement(menuDetails.list.filter(x => selectedFilter.values.includes(x.year)), 'slNo').reverse());
        }
        else {
            setListToDisplay(sortArrayByElement(menuDetails.list, 'order'));
        }
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/publicationsBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>PUBLICATIONS</Header>
                    </div>
                    <div className='pageSegment'>
                        <Menu fluid attached='top' tabular className='publicationsMenu'>
                            {menuList.map((menu) => {
                                return (
                                    <>
                                        <MenuItem
                                            to={'/publications/' + menu}
                                            active={activeItem.name === menu}
                                            key={menu}
                                            as={NavLink}
                                            onClick={() => onMenuSelection(menu)}
                                        >{menu}</MenuItem>
                                    </>
                                )
                            })}
                        </Menu>
                        <Segment attached='bottom'>
                            {(!isUndefinedOrNull(activeItem) && (activeItem.name === 'Publications' || activeItem.name === 'Conference Presentations')) &&
                                <div className='filterContainer'>
                                    {filterOptions.map((y) => {
                                        return (
                                            <>
                                                <div className={selectedFilter.label === y.label ? 'active filterOptions' : 'filterOptions'} onClick={() => onFilterClick(y)} key={y.label}>{y.label}</div>
                                            </>
                                        )
                                    })}
                                </div>
                            }
                            <div className='listContainerWithYear'>
                                {!isUndefinedOrNull(activeItem) &&
                                    <>
                                        {(activeItem.name === 'Publications' || activeItem.name === 'Conference Presentations') &&
                                            <>
                                                {selectedFilter.values.sort().reverse().map((yr) => {
                                                    return (
                                                        <>
                                                            <div key={yr}>
                                                                {listToDisplay.filter(x => x.year === yr).length > 0 &&
                                                                    <Header as='h2'>{yr}</Header>
                                                                }
                                                                <div className='listContainer'>
                                                                    {listToDisplay.filter(x => x.year === yr).map((listData) => {
                                                                        return (
                                                                            <>
                                                                                <div className='itemDetails' key={listData.title}>
                                                                                    <span className='listNumber'>{listData.slNo}.</span>
                                                                                    <span dangerouslySetInnerHTML={{ __html: listData.title }}></span>
                                                                                    {!isUndefinedOrNull(listData.DOILink) &&
                                                                                        <span>(DOI: <a href={listData.DOILink} target='_blank' rel='noreferrer'>{listData.DOIText}</a>)</span>
                                                                                    }
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                })
                                                }
                                            </>
                                        }
                                        {(activeItem.name === 'Book Chapters' || activeItem.name === 'Patents') &&
                                            <div className='listContainer'>
                                                {listToDisplay.map((listData) => {
                                                    return (
                                                        <>
                                                            <div className='itemDetails' key={listData.title}>
                                                                <span className='listNumber'>{listData.order}.</span>
                                                                <span dangerouslySetInnerHTML={{ __html: listData.title }}></span>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </Segment>
                    </div>
                </div>
            }
        </>
    );
}

export default Publications;