import { useContext, useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';
import GalleryModal from './../Components/GalleryModal';
function AreasOfInterest() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [listToDisplay, setListToDisplay] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const [time, setTime] = useState(0);
    const [showPublications, setShowPublications] = useState([]);
    const { areasOfInterestData: [areasOfInterestData, setAreasOfInterestData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (areasOfInterestData === null) {
            setIsLoaderActive(true);
            getData('areasOfInterest').then(res => {
                setAreasOfInterestData(res);
                setDataToDisplay(sortArrayByElement(res, 'order'));
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(areasOfInterestData);
        }
    }, []);
    useEffect(() => {
        setInterval(() => {
            setTime((prev) => prev + 1);
        }, 5000);
    }, [])
    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/areasOfInterestBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>AREAS OF INTEREST</Header>
                    </div>
                    <div className='pageSegment'>
                        {dataToDisplay.map((areasOfInterestItem) => {
                            return (
                                <>
                                    <div className='areasOfInterestContent' key={areasOfInterestItem.title}>
                                        <div className='areasOfInterestImgContainer'>
                                            <Image src={areasOfInterestItem.image} alt='' className='areasOfInterestImg' />
                                            <span className='areasOfInterestTitleContainer'>
                                                <Header as='h2' className='areasOfInterestTitle'>
                                                    {areasOfInterestItem.title}
                                                </Header>
                                            </span>
                                        </div>
                                        {!isUndefinedOrNull(areasOfInterestItem.relatedImages) &&
                                            <div className='motionPicturesContainer'>
                                                <div className='motionPictures'>
                                                    {areasOfInterestItem.relatedImages.map((img, i) => {
                                                        return (
                                                            <>
                                                                {time % areasOfInterestItem.relatedImages.length === i &&
                                                                    <>
                                                                        <Image src={img.image} alt='' className='relatedImages' onClick={() => { setSelectedImage(img); setListToDisplay(areasOfInterestItem.relatedImages) }} />
                                                                    </>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        }
                                        <div className='contentText'>
                                            <div className='mb-2' dangerouslySetInnerHTML={{ __html: areasOfInterestItem.description }}></div>
                                            {!isUndefinedOrNull(areasOfInterestItem.relatedPublications) &&
                                                <div className='relatedPublicationsContainer'>
                                                    {areasOfInterestItem.relatedPublications.map((pub, i) => {
                                                        return (
                                                            <>
                                                                {!showPublications.includes(areasOfInterestItem.id) && i < 3 &&
                                                                    <>
                                                                        <div className='relatedPublications'>
                                                                            {!isUndefinedOrNull(pub.link) ?
                                                                                <a href={pub.link}target = '_blank'>{pub.title}</a> : <a >{pub.title}</a>
                                                                            }
                                                                        </div>
                                                                        {i === 2 &&
                                                                            <a className='cursor-pointer' onClick={() => setShowPublications((arr) => [...arr, ...[areasOfInterestItem.id]])}><i><u>View more...</u></i></a>
                                                                        }
                                                                    </>
                                                                }
                                                                {showPublications.includes(areasOfInterestItem.id) &&
                                                                    <>
                                                                        <div className='relatedPublications'>
                                                                            {!isUndefinedOrNull(pub.link) ?
                                                                                <a href={pub.link}>{pub.title}</a> : <a >{pub.title}</a>
                                                                            }
                                                                        </div>
                                                                        {i === areasOfInterestItem.relatedPublications.length - 1 &&
                                                                            <a className='cursor-pointer' onClick={() => setShowPublications(showPublications.filter(x => x !== areasOfInterestItem.id))}><i><u>View less...</u></i></a>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            }
                                        </div>
                                    </div >
                                </>
                            )
                        })}
                    </div>
                    {!isUndefinedOrNull(selectedImage) &&
                        <GalleryModal galleryData={listToDisplay} selectedImage={selectedImage} onCloseModal={() => setSelectedImage()} />
                    }
                </div >
            }
        </>
    );
}

export default AreasOfInterest;