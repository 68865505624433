import { useState } from 'react';
import { Header, Image, Modal, ModalContent, ModalDescription, ModalHeader } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from './../Service/Helpers';

function ProfileModal({ profileData, onCloseModal }) {
    const [open, setOpen] = useState(true);
    const onClose = () => {
        setOpen(false);
        onCloseModal();
    }
    return (
        <>
            <Modal
                onClose={() => onClose()}
                onOpen={() => setOpen(true)}
                open={open}
                dimmer='blurring'
                size='large'
                closeOnEscape={true}
                closeOnDimmerClick={true}
                closeIcon
            >
                <ModalHeader>{profileData.name}
                    {!isUndefinedOrNull(profileData.role) &&
                        <>
                            <br />
                            <span className='modalRole'> {profileData.role}</span>
                        </>
                    }
                </ModalHeader>
                <ModalContent image>
                    <Image size='medium' src={profileData.image} wrapped />
                    <ModalDescription>
                        {!isUndefinedOrNull(profileData.researchTitle) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel'>Research Title</Header>
                                <span dangerouslySetInnerHTML={{ __html: profileData.researchTitle }}></span>
                            </div>
                        }
                        {!isUndefinedOrNull(profileData.email) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel'>Email</Header>
                                {profileData.email}
                            </div>
                        }
                        {!isUndefinedOrNull(profileData.phd) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel'>PhD</Header>
                                {profileData.phd}
                            </div>
                        }
                        {!isUndefinedOrNull(profileData.msc) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel'>M.Sc</Header>
                                {profileData.msc}
                            </div>
                        }
                        {!isUndefinedOrNull(profileData.bsc) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel'>B.Sc</Header>
                                {profileData.bsc}
                            </div>
                        }
                        {!isUndefinedOrNull(profileData.recognitions) &&
                            <div>
                                <Header as='h4' className='profileElementsLabel recognitions'>Recognitions</Header>
                                <ul className='recognitionsDetails'>
                                    {sortArrayByElement(profileData.recognitions, 'order').map((rec) => {
                                        return (
                                            <>
                                                <li className='recognitionsName' key={rec.name} dangerouslySetInnerHTML={{ __html: rec.name }}></li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </div>
                        }
                    </ModalDescription>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ProfileModal;