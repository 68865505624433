import { useContext, useEffect, useState } from 'react';
import { Form, FormButton, FormGroup, FormInput, FormTextArea, Grid, GridColumn, GridRow, Header, Icon, Image } from 'semantic-ui-react';
import { v4 as uuidv4 } from 'uuid';
import { isUndefinedOrNull } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData, postData, postFile } from '../Service/Request';

function Contact() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const { contactData: [contactData, setContactData] } = useContext(LocalStorage);
    const [fileData, setFileData] = useState();
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);
    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (contactData === null) {
            setIsLoaderActive(true);
            getData('contactDetails').then(res => {
                setContactData(res);
                setDataToDisplay(res);
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(contactData);
        }
    }, []);

    const handleChange = (e) => {
        let selectedFile = e.target.files[0];
        if (!isUndefinedOrNull(selectedFile)) {
            if (selectedFile.size > (10 * 1024 * 1024)) {
                alert("File size exceeds the limit.");
            }
            else {
                setFileData(selectedFile);
            }
        }
    }

    const onRemoveFile = () => {
        setFileData(null);
        document.getElementById('file').value = '';
    }

    const onSend = () => {
        let name = document.getElementById('name')?.value;
        let email = document.getElementById('email')?.value;
        let phone = document.getElementById('phone')?.value;
        let address = document.getElementById('address')?.value;
        let subject = document.getElementById('subject')?.value;
        let message = document.getElementById('message')?.value;
        if (!isUndefinedOrNull(name) && !isUndefinedOrNull(email) && !isUndefinedOrNull(phone)
            && !isUndefinedOrNull(address) && !isUndefinedOrNull(subject) && !isUndefinedOrNull(message)) {
            let phoneRegex = /^\d{10}$/;
            if (phoneRegex.test(phone)) {
                let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (emailRegex.test(email)) {
                    let body = {
                        name: name,
                        email: email,
                        phone: phone,
                        address: address,
                        subject: subject,
                        message: message,
                        timestamp: new Date().toISOString(),
                        id: uuidv4()
                    }
                    if (!isUndefinedOrNull(fileData)) {
                        let formData = new FormData();
                        formData.append('file', fileData, fileData.name);
                        postFile(formData).then(postFileRes => {
                            if (!isUndefinedOrNull(postFileRes)) {
                                body['file'] = postFileRes.file;
                                postData('addRequest', body).then(postAddReqRes => {
                                    if (!isUndefinedOrNull(postAddReqRes)) {
                                        document.getElementById('inputForm')?.reset();
                                        setFileData(null);
                                        alert("Details submitted successfully");
                                    }
                                    else {
                                        alert("Some issue, try after sometime");
                                    }
                                });
                            }
                            else {
                                alert("Some issue, try after sometime");
                            }
                        });
                    }
                    else {
                        postData('addRequest', body).then(postAddReqRes => {
                            alert("Details submitted successfully");
                            document.getElementById('inputForm')?.reset();
                        });
                    }
                }
                else{
                    alert("Invalid email.")
                }
            }
            else {
                alert("Invalid phone number.");
            }
        }
        else {
            alert('Fill required field to send.');
        }
    }

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/contactBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>CONTACT US</Header>
                    </div>
                    <div className='pageSegment contact'>
                        <div className='contactDetails'>
                            <Grid padded>
                                <GridRow >
                                    <GridColumn width='1'>
                                        <Icon name='map marker alternate' size='large' />
                                    </GridColumn>
                                    <GridColumn width='15'>
                                        <div dangerouslySetInnerHTML={{ __html: dataToDisplay.address }}></div>
                                    </GridColumn>
                                </GridRow>
                                <GridRow >
                                    <GridColumn width='1'>
                                        <Icon name='mail' size='large' />
                                    </GridColumn>
                                    <GridColumn width='15'>
                                        <div dangerouslySetInnerHTML={{ __html: dataToDisplay.email }}></div>
                                    </GridColumn>
                                </GridRow>
                                <GridRow >
                                    <GridColumn width='1'>
                                        <Icon name='phone' size='large' />
                                    </GridColumn>
                                    <GridColumn width='15'>
                                        <div dangerouslySetInnerHTML={{ __html: dataToDisplay.phoneNumber }}></div>
                                    </GridColumn>
                                </GridRow>
                                <GridRow >
                                    <GridColumn width='16'>
                                        {!isUndefinedOrNull(dataToDisplay.facebook) &&
                                            <>
                                                <a href={dataToDisplay.facebook} target='_blank' rel='noreferrer' className='colorBlack'><Icon name='facebook official' size='large' /></a>
                                            </>
                                        }
                                        {!isUndefinedOrNull(dataToDisplay.twitter) &&
                                            <>
                                                <a href={dataToDisplay.twitter} target='_blank' rel='noreferrer' className='colorBlack'><Icon name='twitter' size='large' /></a>
                                            </>
                                        }
                                        {!isUndefinedOrNull(dataToDisplay.linkedIn) &&
                                            <>
                                                <a href={dataToDisplay.linkedIn} target='_blank' rel='noreferrer' className='colorBlack'><Icon name='linkedin' size='large' /></a>
                                            </>
                                        }
                                        {!isUndefinedOrNull(dataToDisplay.instagram) &&
                                            <>
                                                <a href={dataToDisplay.instagram} target='_blank' rel='noreferrer' className='colorBlack'><Icon name='instagram' size='large' /></a>
                                            </>
                                        }
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </div>
                        <div className='divider'></div>
                        <div className='contactForm'>
                            <Form className='adminInputContainer' id='inputForm' autocomplete='off'>
                                <FormGroup widths='equal'>
                                    <FormInput fluid label='Name' type='text' id='name' placeholder='Enter your name' required />
                                    <FormInput fluid label='Email' type='text' id='email' placeholder='Enter your email' required />
                                </FormGroup>
                                <FormGroup widths='equal'>
                                    <FormInput fluid label='Phone Number' type='text' id='phone' placeholder='Enter your 10 digit phone number' required />
                                    <FormInput fluid label='Address' type='text' id='address' placeholder='Enter your address' required />
                                </FormGroup>
                                <FormInput fluid label='Subject' tyep='text' id='subject' placeholder='Enter the subject' required />
                                <FormTextArea label='Message' placeholder='Type the message here...' id='message' required />
                                <FormInput fluid width='16' label='Upload file' id='file' type='file' onChange={(event) => handleChange(event)} className='adminInput fileUpload' />
                                <div><b><small>Maximum upload file size: 10MB</small></b></div>
                                {!isUndefinedOrNull(fileData) &&
                                    <>
                                        <div className='filePreview'>
                                            <a href={URL.createObjectURL(fileData)} target='_blank' rel='noreferrer'>{fileData.name}</a>
                                            <Icon className='imageCloseIcon' name='remove circle' size='large' color='red' onClick={() => onRemoveFile()} />
                                        </div>
                                    </>
                                }
                            </Form>
                            <FormButton color='olive' content='Send' onClick={() => onSend()} />
                        </div>
                    </div >
                </div>
            }
        </>
    );
}

export default Contact;