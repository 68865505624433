import { createContext, useState } from 'react';

export const LocalStorage = createContext(null);

export default ({ children }) => {
    const [isSideMenuActive, setIsSideMenuActive] = useState(false);

    const [navigationList] = useState([
        { to: '/rgb', displayText: 'PROF. R GEETHA BALAKRISHNA' },
        { to: '/area-of-interest', displayText: 'AREAS OF INTEREST' },
        {
            displayText: 'PUBLICATIONS', sub: [
                { to: '/publications/Publications', displayText: 'Publications' },
                { to: '/publications/Book Chapters', displayText: 'Book Chapters' },
                { to: '/publications/Patents', displayText: 'Patents' },
                { to: '/publications/Conference Presentations', displayText: 'Conference Presentations' }
            ]
        },
        {
            displayText: 'GROUP MEMBERS', sub: [
                { to: '/our-research-group/Faculties', displayText: 'Faculties' },
                { to: '/our-research-group/Postdocs-Research Associates', displayText: 'Postdocs/Research Associates' },
                { to: '/our-research-group/Research Students', displayText: 'Research Students' },
                { to: '/our-research-group/PhD Alumni', displayText: 'PhD Alumni' }
            ]
        },
        { to: '/facilities', displayText: 'FACILITIES' },
        {
            displayText: 'GALLERY', sub: [
                { to: '/gallery/Awards', displayText: 'Awards' },
                { to: '/gallery/Events', displayText: 'Events' },
                { to: '/gallery/Lab Outings', displayText: 'Lab Outings' }
            ]
        }
    ]);

    const [rgbData, setRGBData] = useState(null);
    const [areasOfInterestData, setAreasOfInterestData] = useState(null);
    const [contactData, setContactData] = useState(null);
    const [facilitiesData, setFacilitiesData] = useState(null);
    const [galleryData, setGalleryData] = useState(null);
    const [groupMembersData, setGroupMembersData] = useState(null);
    const [homeData, setHomeData] = useState(null);
    const [latestNewsData, setLatestNewsData] = useState(null);
    const [publicationsData, setPublicationsData] = useState(null);
    const [isLoaderActive, setIsLoaderActive] = useState(false);

    const store = {
        isSideMenuActive: [isSideMenuActive, setIsSideMenuActive],
        navigationList: [navigationList],
        rgbData: [rgbData, setRGBData],
        areasOfInterestData: [areasOfInterestData, setAreasOfInterestData],
        contactData: [contactData, setContactData],
        facilitiesData: [facilitiesData, setFacilitiesData],
        galleryData: [galleryData, setGalleryData],
        groupMembersData: [groupMembersData, setGroupMembersData],
        homeData: [homeData, setHomeData],
        latestNewsData: [latestNewsData, setLatestNewsData],
        publicationsData: [publicationsData, setPublicationsData],
        isLoaderActive: [isLoaderActive, setIsLoaderActive]
    }

    return (<LocalStorage.Provider value={store}>{children}</LocalStorage.Provider>);
}