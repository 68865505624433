
import { useContext, useState, useEffect} from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dimmer, Icon, Loader, Menu, Segment, Sidebar, SidebarPushable, SidebarPusher } from 'semantic-ui-react';
import './App.css';
import AppFooter from './Components/AppFooter';
import AppHeaders from './Components/AppHeader';
import NavigationList from './Components/NavigationList';
import AreasOfInterest from './Pages/AreasOfInterest';
import Contact from './Pages/Contact';
import Facilities from './Pages/Facilities';
import Gallery from './Pages/Gallery';
import GroupMembers from './Pages/GroupMembers';
import Home from './Pages/Home';
import LatestNews from './Pages/LatestNews';
import Publications from './Pages/Publications';
import RGB from './Pages/RGB';
import { LocalStorage } from './Service/LocalStorage';
import { v4 as uuidv4 } from 'uuid';
import { postData } from './Service/Request';

function App() {
  const { isSideMenuActive: [isSideMenuActive, setIsSideMenuActive] } = useContext(LocalStorage);
  const { isLoaderActive: [isLoaderActive] } = useContext(LocalStorage);
  const [scrollToTopVisible, setScrollToTopVisible] = useState(true);
  const scrollToTop = () => {
    console.log(window.screenY);
    document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    postData('dataLog', { id: uuidv4() });
  }, []);

  return (
    <>
      <Dimmer active={isLoaderActive}>
        <Loader content='Loading' />
      </Dimmer>
      <SidebarPushable as={Segment}>
        <Sidebar
          as={Menu}
          animation='overlay'
          icon='labeled'
          direction='top'
          width='wide'
          inverted
          vertical
          visible={isSideMenuActive}
          onHide={() => setIsSideMenuActive(false)}>
          <NavigationList></NavigationList>
        </Sidebar>

        <SidebarPusher dimmed={isSideMenuActive}>
          <AppHeaders></AppHeaders>
          <div className='pageContainer'>
            <Routes>
              <Route path='/' element={<Navigate to='/home' />} />
              <Route path='/home' element={<Home />} />
              <Route path='/rgb' element={<RGB />} />
              <Route path='/area-of-interest' element={<AreasOfInterest />} />
              <Route path='/publications/:category' element={<Publications />} />
              <Route path='/publications' element={<Publications />} />
              <Route path='/our-research-group/:category' element={<GroupMembers />} />
              <Route path='/our-research-group' element={<GroupMembers />} />
              <Route path='/facilities' element={<Facilities />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/gallery/:category' element={<Gallery />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/latest-news' element={<LatestNews />} />
              <Route path='*' element={<Navigate to='/home' />} />
            </Routes>
          </div>
          <AppFooter></AppFooter>
        </SidebarPusher>
      </SidebarPushable>
      {scrollToTopVisible &&
        <>
          <div className='ScrollTop' onClick={() => scrollToTop()}>
            <Icon name='arrow alternate circle up outline' size='large' className='m-0' color='white' inverted />
          </div>
        </>}
    </>
  );
}

export default App;