import { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';

function Home() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const { homeData: [homeData, setHomeData] } = useContext(LocalStorage);
    const { latestNewsData: [latestNewsData, setLatestNewsData] } = useContext(LocalStorage);
    const { publicationsData: [publicationsData, setPublicationsData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (homeData === null) {
            setIsLoaderActive(true);
            getData('home').then(res => {
                setHomeData(res);
                setDataToDisplay(res);
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(homeData);
        }
        if (latestNewsData === null) {
            getData('latestNews').then(res => {
                setLatestNewsData(res);
            });
        }
        if (publicationsData === null) {
            getData('publications').then(res => {
                setPublicationsData(res);
            });
        }
    }, []);

    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) && !isUndefinedOrNull(publicationsData) && !isUndefinedOrNull(latestNewsData) &&
                <div className='page '>
                    <div className='carsolContainer'>
                        <Image src={dataToDisplay.banner} alt='' className='carsolImg' />
                        <Header as='h1' className='pageHeader homePageHeader'>WELCOME<br />TO OUR GROUP</Header>
                    </div>
                    <div className='pageSegment'>
                        <div className='cardHolder'>
                            {sortArrayByElement(dataToDisplay.cardList, 'order').map((cards) => {
                                return (
                                    <>
                                        <div className='card' key={cards.image}>
                                            <Image src={cards.image} alt='' className='cardImg' />
                                            <div className='cardDetails'>
                                                <Header as='h3' className='cardHeader'>{cards.header}</Header>
                                                <div dangerouslySetInnerHTML={{ __html: cards.description }}></div>
                                                <a href={cards.navigateTo}>Read more...</a>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                        <div className='selectedPublicationsAndLatestNews'>
                            <div className='selectedPublications'>
                                <Header as='h2'>Selected Publications</Header>
                                <div className='selectedPublicationsDetails'>
                                    {sortArrayByElement(publicationsData.find(x => x.name === 'Publications').list.filter(y => y.isSelected === true), 'slNo').reverse().slice(0, 10).map((selPub, index) => {
                                        return (
                                            <>
                                                <div className='itemDetails' key={selPub.title}>
                                                    <span className='listNumber'>{index + 1}.</span>
                                                    <span dangerouslySetInnerHTML={{ __html: selPub.title }}></span>
                                                    <br />
                                                    <span>(DOI: <a href={selPub.DOILink} target='_blank' rel='noreferrer'>{selPub.DOIText}</a>)</span>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className='latestNewsAndAnnualReport'>
                                <div className='latestNews'>
                                    <Header>Latest News</Header>
                                    <div className='latestNewsContainer'>
                                        {sortArrayByElement(latestNewsData, 'date').reverse().slice(0, 5).map((latestNews) => {
                                            return (
                                                <>
                                                    <div className='latestNewsEvent'>
                                                        <div className='latestNewsTitle' dangerouslySetInnerHTML={{ __html: latestNews.title }}></div>
                                                        <i>{latestNews.date}</i>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    <a href='/latest-news'><i><u>View more...</u></i></a>
                                </div>
                                <div className='annualReport'>
                                    <Header>Annual Reports</Header>
                                    <div className='annualReportContainer'>
                                        {sortArrayByElement(dataToDisplay.annualReport, 'year').reverse().map((annualReport) => {
                                            return (
                                                <>
                                                    <a href={annualReport.doc} target='_blank' rel='noreferrer' download={annualReport.doc}><u>{annualReport.year}</u></a>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className='visitorCount'>
                                    <Header>Visitor Count</Header>
                                    <div className='visitorCountContainer'><Icon name='eye'></Icon>{dataToDisplay.visitorCount}+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='jobOpenings'>
                        <div className='jobOpeningsContainer'>
                            <Header as='h2'>Current Opportunities at RGB's Photo and Electrocatalysis Lab</Header>
                            <p className='jobOpeningsDetails'>
                                For Ph.D. & Post doc positions, please visit<br />
                                <a href='https://cnms.jainuniversity.ac.in/careers.htm' target='_blank' rel='noreferrer'>https://cnms.jainuniversity.ac.in/careers.htm</a>
                                <br />
                                <Button content='Contact Us' primary onClick={() => window.location.href = '/contact'} />
                            </p>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Home;