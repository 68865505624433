import { useContext, useEffect, useState } from 'react';
import { Header, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { LocalStorage } from '../Service/LocalStorage';
import { getData } from '../Service/Request';
function LatestNews() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const [years, setYears] = useState(null);
    const { latestNewsData: [latestNewsDate, setLatestNewsData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (latestNewsDate === null) {
            setIsLoaderActive(true);
            getData('latestNews').then(res => {
                setLatestNewsData(res);
                setDataToDisplay(res);
                setYears(Array.from(new Set(res.map((news) => { return (Number(news.date.split(', ')[1])) }).sort().reverse())));
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay('date');
            setYears(Array.from(new Set(latestNewsDate.map((news) => { return (Number(news.date.split(', ')[1])) }).sort().reverse())));
        }
    }, [])

    const onFilterClick = (year) => {
        document.getElementById(year.toString()).scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            {!isUndefinedOrNull(latestNewsDate) &&
                <div className='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/latestNewsBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader'>LATEST NEWS</Header>
                    </div>
                    <div className='pageSegment'>
                        <div className='filterContainer'>
                            {years.map((y) => {
                                return (
                                    <>
                                        <div className='filterOptions' onClick={() => onFilterClick(y)} key={y}>{y}</div>
                                    </>
                                )
                            })}
                        </div>
                        <div className='latestNewsSegment'>
                            {years.map((yr) => {
                                return (
                                    <>
                                        <div className='newsYearContainer' key={yr} id={yr.toString()}>
                                            <div className='newsYear'>{yr}</div>
                                            <div className='newsContainer'>
                                                {sortArrayByElement(dataToDisplay.filter(x => x.date.includes(yr.toString())), 'date').reverse().map((news) => {
                                                    return (
                                                        <>
                                                            <div key={news.title}>
                                                                <div className='latestNewsTitle' dangerouslySetInnerHTML={{ __html: news.title }}></div>
                                                                <i>{news.date}</i>
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default LatestNews;