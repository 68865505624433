import { useContext, useEffect, useState } from 'react';
import { Button, Header, Icon, Image } from 'semantic-ui-react';
import { isUndefinedOrNull, sortArrayByElement } from '../Service/Helpers';
import { getData } from '../Service/Request';
import { LocalStorage } from './../Service/LocalStorage';

function RGB() {
    const [dataToDisplay, setDataToDisplay] = useState(null);
    const { rgbData: [rgbData, setRGBData] } = useContext(LocalStorage);
    const { isLoaderActive: [, setIsLoaderActive] } = useContext(LocalStorage);

    useEffect(() => {
        document.getElementById('header').scrollIntoView({ behavior: 'smooth' });
        if (rgbData === null) {
            setIsLoaderActive(true);
            getData('rgb').then(res => {
                setRGBData(res);
                setDataToDisplay(res);
                setIsLoaderActive(false);
            });
        }
        else {
            setDataToDisplay(rgbData);
        }
    }, [])
    return (
        <>
            {!isUndefinedOrNull(dataToDisplay) &&
                <div className='page' id='page'>
                    <div className='pageHeaderBackground'>
                        <Image src="https://api.cnmsrgblab.com/media/images/rgbBg.jpg" alt='' className='pageHeaderImg' />
                        <Header as='h1' className='pageHeader rgbHeader' >PROF. R GEETHA BALAKRISHNA</Header>
                        {!isUndefinedOrNull(dataToDisplay.profileData.scrollingText) &&
                            <div className='scrollingText'>
                                <marquee direction='left' height='100%'><h3>{dataToDisplay.profileData.scrollingText}</h3></marquee>
                            </div>
                        }
                    </div>

                    <div className='pageSegment'>
                        <div className='rgbDetailsAndRecognitions'>
                            <div className='rgbImageAndDetails'>
                                <Image src={dataToDisplay.profileData.image} alt='' className='rgbImage' />
                                <div className='rgbDetails'>
                                    <Header as='h2' className='rgbName'>{dataToDisplay.profileData.name}</Header>
                                    <div className='locationDetails'>
                                        <div className='postionAndProfileBtn'>
                                            <b>{dataToDisplay.profileData.position}</b>
                                            <Button size='mini' color='blue' content='Profile' icon='download' labelPosition='right' as='a' href={dataToDisplay.profileData.doc} download={dataToDisplay.profileData.doc} target='_blank' rel='noreferrer' ></Button>
                                        </div>
                                        <div>{dataToDisplay.profileData.department}</div>
                                        <div>{dataToDisplay.profileData.university}</div>
                                    </div>
                                    <div><Icon name='phone' />{dataToDisplay.profileData.phone}</div>
                                    <div><Icon name='mail' />{dataToDisplay.profileData.email}</div>
                                </div>
                            </div>
                            {!isUndefinedOrNull(dataToDisplay.recognitions) &&
                                <div className='recognitions'>
                                    <Header as='h2'>Recognitions</Header>
                                    <div className='recognitionsContainer'>
                                        <ul className='recognitionsDetails'>
                                            {sortArrayByElement(dataToDisplay.recognitions, 'order').map((recognitions) => {
                                                return (
                                                    <>
                                                        <li className='recognitionsName' key={recognitions.title} dangerouslySetInnerHTML={{ __html: recognitions.title }}></li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            }
                        </div>
                        {!isUndefinedOrNull(dataToDisplay.profileData.description) &&
                            <div className='rgbDescription' dangerouslySetInnerHTML={{ __html: dataToDisplay.profileData.description }}></div>
                        }
                        <div className='positionDetailsConatiner'>
                            {!isUndefinedOrNull(dataToDisplay.academicPositions) &&
                                <div className='position'>
                                    <Header as='h2'>Academic Positions</Header>
                                    <div className='positionContainer'>
                                        {sortArrayByElement(dataToDisplay.academicPositions, 'from').reverse().map((academicPos) => {
                                            return (
                                                <>
                                                    <div className='positionSegment' key={academicPos.from + ' ' + academicPos.to}>
                                                        <div className='positionYear'>{academicPos.from} - {academicPos.to}</div>
                                                        <div className='positionDetails'>
                                                            <Header as='h3' className='positionText'>{academicPos.position}</Header>
                                                            <div dangerouslySetInnerHTML={{ __html: academicPos.description }}></div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                            {!isUndefinedOrNull(dataToDisplay.researchPositions) &&
                                <div className='position'>
                                    <Header as='h2'>Research Positions</Header>
                                    <div className='positionContainer'>
                                        {sortArrayByElement(dataToDisplay.researchPositions, 'year').reverse().map((researchPos) => {
                                            return (
                                                <>
                                                    <div className='positionSegment' key={researchPos.year}>
                                                        <div className='positionYear'>{researchPos.year}</div>
                                                        <div className='positionDetails'>
                                                            <Header as='h3' className='positionText'>{researchPos.position}</Header>
                                                            <div dangerouslySetInnerHTML={{ __html: researchPos.description }}></div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        {!isUndefinedOrNull(dataToDisplay.profileData.education) &&
                            <div className='education'>
                                <Header as='h2'>Education</Header>
                                <div className='educationDetails'>
                                    <ul>
                                        {sortArrayByElement(dataToDisplay.profileData.education, 'order').map(edu => {
                                            return (
                                                <>
                                                    <li>{edu.title}</li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        }
                        {!isUndefinedOrNull(dataToDisplay.projectsHandled) &&
                            <div className='projectsHandled'>
                                <Header as='h2'>Projects Handled</Header>
                                <div className='projectsHandledDetails'>
                                    {sortArrayByElement(dataToDisplay.projectsHandled, 'order').map((compProj) => {
                                        return (
                                            <>
                                                <div className='itemDetails' key={compProj.title}>
                                                    <span className='listNumber'>{compProj.order}.</span>
                                                    <span dangerouslySetInnerHTML={{ __html: compProj.title }}></span>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default RGB;