import axios from 'axios';

export const getData = async (path) => {
    return await axios
        .get('https://api.cnmsrgblab.com/' + path + '/')
        .then(res => { return res.data })
        .catch(error => console.log(error));
}

export const postData = async (path, body) => {
    return await axios
        .post('https://api.cnmsrgblab.com/' + path + '/', body)
        .then(res => { 
            if (res.status === 200) {
                return res.data;
            }
            else{
                return undefined;
            }
         })
        .catch(error => console.log(error));
}

export const postFile = async (body) => {
    return await axios
        .post('https://api.cnmsrgblab.com/uploadUserFile/', body, { headers: { 'content-type': 'multipart/form-data' } })
        .then(res => { 
            if (res.status === 200) {
                return res.data;
            }
            else{
                return undefined;
            }
         })
        .catch(error => console.log(error));
}